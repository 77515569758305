import {apiGet, apiPost} from "./common"
import {formatDtShort} from "../utils/format";
import {formatDtExternal, parseDtInternal} from "../utils/format";

/////
/////   ProductKey
/////

export interface ProductKey {
  code: string
  pcLogin: string
  codeSourcePayment: string
}

/////
/////   SummaryReportEntry
/////

export interface SummaryReportEntry extends ProductKey {
  chosenPartner: string
  amountGranted: number,
  amountRepaid: number
}

/////
/////   SubscriberInfo
/////

export type SubscriberInfo = {
  error?: SubscriberInfoError,
  subscriberId: string,

  balance: number,
  balanceSimpleChecks: number,
  balanceTotal: number,

  branchId: number,
  branchName?: string,
  branchOrg?: string,
  activationDate?: string
}

export type SubscriberInfoError = {
  code: string,
  message?: string
}

/////
/////   MnoInfo
/////

export type MnoInfo = {
  id: string,
  title: string,
  country: string,
  region: string,
  capital: string,
  timezone: string
}

/////
/////   MkEntry
/////

export type Service = "t2t" | "t2p" | "payment"

export type TxType = "m2c" | "m2m" | "m2a" |
  "pp" | "dp" | "dn" | "dns" | "pdp" | "pdps" | "sp"

export type TxState = "success" | "failed" | "processing"

export type MkEntry = {
  id: string,
  ts: string,

  service: Service,
  type: TxType,
  state: TxState,
  provider: string,
  error: string,

  amount: number,
  fee?: number
}

/////
/////   SmsEntry
/////

export type SmsEntry = {
  ts: string,
  direction: "I" | "O",
  message: string,
  opName: string,
  status: string
}

/////
/////   SimEntry
/////

export type SimEntry = {
  id: string,
  carrier: string
}

export type BoxSmsEntry = {
  id: string,
  from: string,
  msg: string,
  to: string,
  ts: string
}


/////
/////
/////

const normDt = (val: string) => formatDtExternal(parseDtInternal(val))

class ReportApi {

  async repaymentSummaryReport(values: object): Promise<SummaryReportEntry[]> {
    console.log('ReportApi.repaymentSummaryReport')
    return apiPost(`/report/repayment/summary`, values)
  }

  repaymentDetailsUri(params: any): string {
    const filename = encodeURIComponent(
      `Т2П-Погашения(${formatDtShort(params.grantedFrom)}-${formatDtShort(params.grantedTill)}).xlsx`
    )
    //console.log('filename', filename)
    const paramsEncoded = encodeURIComponent(JSON.stringify(params))
    const uri = `/report/repayment/details/${filename}?params=${paramsEncoded}`
    //console.log('repaymentDetailsUri', uri)
    return uri
  }

  async productKeys(): Promise<ProductKey[]> {
    console.log('ReportApi.productKeys')
    return apiGet(`/report/repayment/product_keys`)
  }

  async partners(): Promise<string[]> {
    console.log('ReportApi.partners')
    return apiGet(`/report/repayment/partners`)
  }

  async fetchBalance(msisdn: string): Promise<SubscriberInfo> {
    console.log('ReportApi.fetchBalance', msisdn)
    return apiGet(`/info/balance?msisdn=${msisdn}`)
  }

  async fetchMnoInfo(msisdn: string): Promise<MnoInfo> {
    console.log('ReportApi.fetchMnoInfo', msisdn)
    return apiGet(`/info/mno?msisdn=${msisdn}`)
  }

  async listMk(msisdn: string, from: string, till: string): Promise<MkEntry[]> {
    console.log('ReportApi.listMk', msisdn, from, till)
    return apiGet(`/detailed/tx?msisdn=${msisdn}&from=${normDt(from)}&till=${normDt(till)}`)
  }

  async mkDetails(service: string, id: string): Promise<any> {
    console.log('ReportApi.mkDetails', service, id)
    return apiGet(`/detailed/tx/${service}/${id}`)
  }

  async listSms(msisdn: string, from: string, till: string): Promise<SmsEntry[]> {
    console.log('ReportApi.listSms', msisdn, from, till)
    return apiGet(`/detailed/sms?msisdn=${msisdn}&from=${normDt(from)}&till=${normDt(till)}`)
  }

  async listBoxSims(): Promise<SimEntry[]> {
    console.log('ReportApi.listSims')
    return apiGet(`/t2x/sim`)
  }

  async listBoxInbox(from: string, till: string, msisdn?: string): Promise<BoxSmsEntry[]> {
    console.log('ReportApi.listBoxInbox', msisdn, from, till)
    return apiGet(`/t2x/sms?from=${normDt(from)}&till=${normDt(till)}` + (msisdn ? `&msisdn=${msisdn}` : ''))
  }
}

export const reportApi = new ReportApi()


