import Moment from "moment-timezone";
import {Rouble} from "../constants/symbols";

export function isNumber(value: string | number): boolean {
  return ((value != null) &&
          (value !== '') &&
          !isNaN(Number(value.toString()))) &&
      !value.toString().startsWith('+') &&
      !value.toString().startsWith('-');
}

export function clearTime(date: Date) {
  let resultDate = new Date(date);
  resultDate.setHours(0, 0, 0, 0);
  return resultDate;

}

export function roundToNextHour(date: Date = new Date()) {
  const p = 60 * 60 * 1000; // milliseconds in an hour
  return new Date(Math.floor(date.getTime() / p) * p + p);
}

export function plusHours(date: Date = new Date(), amt: number) {
  date.setHours(date.getHours() + amt);
  return date;
}

export function formatCurrency(val: number) {
  return `${(val / 100).toFixed(2)} ${Rouble}`
}

export function formatDtHuman(val: Date) {
  return Moment(val).format("DD.MM.yyyy HH:mm");
}

export function formatDtMoscow(val: Date) {
  return Moment.tz(val, "Europe/Moscow").format("DD.MM.yyyy HH:mm z");
}

export function formatDtShort(val: Date) {
  return Moment(val).format("DD.MM.yyyy");
}

export function formatDtInternal(val: Date) {
  return Moment(val).format("yyyy.MM.DD HH:mm");
}

export function parseDtInternal(val: string) {
  return Moment(val, "yyyy.MM.DD HH:mm").toDate();
}

export function formatDtExternal(val: Date) {
  return val.toISOString()
}

export function parseDtExternal(val: string) {
  return new Date(val)
}

export function filterDigits(value: string): string {
  const onlyDigits: string[] = []
  for (let i = 0; i < value.length; i++) {
    if (isNaN(Number(value.charAt(i)))) continue
    onlyDigits.push(value.charAt(i))
  }
  return onlyDigits.join('')
}

export function applyPhoneFormat(digits: string): string {
  if (digits) return '+' + digits
  else return digits
}

export function formatMskDays(val: any): string {
  let isMoment = val instanceof Moment
  if (isMoment) {
    val = val.toDate()
  }
  return Moment.tz({ year: val.getFullYear(), month: val.getMonth(), day: val.getDate()}, 'Europe/Moscow').toISOString()
}

