import * as React from "react";
import { styled } from '@mui/material/styles';
import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    TextField,
    Typography,
    Paper, Autocomplete
} from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from "@mui/material/TablePagination";
import {useEffect, useState} from "react"
import {BoxSmsEntry, reportApi, SimEntry} from "../../api/report"
import {
    formatDtHuman,
    parseDtExternal,
    isNumber,
    roundToNextHour, plusHours, formatDtInternal
} from "../../utils/format"
import {DateTimePicker} from "@mui/x-date-pickers"
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSnackbar } from 'notistack';
import logout from "../../handlers/auth/logout";
import {useAppContext} from "../AppContextProvider";
import {useNavigate} from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    }
}));

interface Column {
    id: 'from' | 'msg' | 'to' | 'ts';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center';
    format?: (value: string) => string | JSX.Element;
}

const columns: readonly Column[] = [
    {id: 'ts', label: 'Время', align: 'center', format: (value: string) => { return formatDtHuman(parseDtExternal(value)) }},
    {id: 'from', label: 'Отправитель', align: 'center', format: (value: string) => value},
    {id: 'to', label: 'Получатель', align: 'center', format: (value: string) => value},
    {id: 'msg', label: 'Сообщение', align: 'center', format: (value: string) => value}
]

export default function BoxInfo() {
    const {dispatch} = useAppContext();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const [msisdn, setMsisdn] = useState<SimEntry>();
    const [sims, setSims] = useState<SimEntry[]>([]);

    const [fromDate, setFromDate] = useState(roundToNextHour(plusHours(new Date(), -7*24)))
    const [fromDateError, setFromDateError] = useState(false);

    const [tillDate, setTillDate] = useState(roundToNextHour(new Date()))
    const [tillDateError, setTillDateError] = useState(false);

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<BoxSmsEntry[]>();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(-1);

    useEffect(() => {
        loadSims().then(() => loadData(undefined));
    }, [])

    const handleLoadButtonClick = () => {
        console.log('handleLoadButtonClick', msisdn)
        loadData(msisdn?.id);
    }

    function logoutIfUnauthorized(errorMsg: string | undefined) {
        if (errorMsg && errorMsg == 'Unauthorized') {
            logout(dispatch, navigate);

        }
    }

    //

    function loadSims() {
        return reportApi.listBoxSims().then(
            response => {
                setSims(response)
            },
            error => {
                logoutIfUnauthorized(error.message);
                console.log('listBoxSims error', error);
                enqueueSnackbar('Ошибка загрузки списка SIM-карт: ' + error.message, { variant: 'error' });
            }
        )
            .then(() => {
                setLoading(false);

            })
    }

    function loadData(msisdn?: string) {
        setLoading(true);
        setData([] as BoxSmsEntry[]);

        reportApi.listBoxInbox(formatDtInternal(fromDate), formatDtInternal(tillDate), msisdn).then(
            response => {
                if(response) {
                    setData(response);

                }
            },
            error => {
                logoutIfUnauthorized(error.message);

                console.log('listSms error', error);
                enqueueSnackbar('Ошибка загрузки списка СМС: ' + error.message, { variant: 'error' });

            }
        )
            .then(() => {
                setLoading(false);

            })

    }

    const handleFromDateChange = (date: Date | null) => {
        if(date) {
            if(date > tillDate) {
                setFromDate(date);
                setTillDate(date);

            } else {
                setFromDate(date);

            }
            loadData(msisdn?.id)

        }

    };

    const handleFromDateError = (error: any) => {
        setFromDateError(error ? true : false);

    };

    const handleTillDateChange = (date: Date | null) => {
        if(date) {
            if(date < fromDate) {
                setFromDate(date);
                setTillDate(date);

            } else {
                setTillDate(date);

            }
            loadData(msisdn?.id)

        }

    };

    const handleTillDateError = (error: any) => {
        setTillDateError(error ? true : false);

    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (<Grid container spacing={2}>
        <Grid item>
            <Autocomplete
                disablePortal
                id="msisdn"
                options={sims}
                value={msisdn}
                onChange={function (event, value) {
                    setMsisdn(value || undefined)
                    loadData(value?.id)
                }}
                size='small'
                sx={{ width: 334 }}
                loading={loading}
                getOptionLabel={(option) => option.id + ` (${option.carrier})`}
                renderInput={(params) =>
                    <TextField {...params} label="Получатель" disabled={loading} />
            }
            />
        </Grid>
        <Grid item>
            <DateTimePicker
                disabled={loading}
                value={fromDate}
                onChange={handleFromDateChange}
                onError={handleFromDateError}
                renderInput={
                    (params) =>
                        <TextField
                            {...params}
                            style={{ width: 200 }}
                            size='small'
                            label='Дата с'
                        />
                }
            />
        </Grid>
        <Grid item>
            <DateTimePicker
                disabled={loading}
                value={tillDate}
                onChange={handleTillDateChange}
                onError={handleTillDateError}
                renderInput={
                    (params) =>
                        <TextField
                            {...params}
                            style={{ width: 200 }}
                            size='small'
                            label='Дата по'
                        />
                }
            />
        </Grid>
        <Grid item>
            <Button
                color='primary'
                disabled={loading || fromDateError || tillDateError}
                onClick={handleLoadButtonClick}>
                Загрузить
            </Button>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        <Grid item xs={12}>

                <TableContainer>
                    <Table stickyHeader aria-label="sms-info-table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{minWidth: column.minWidth}}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { loading ?
                                (<StyledTableRow>
                                    <StyledTableCell colSpan={5} align={'center'}>
                                        <CircularProgress variant={loading ? "indeterminate" : "determinate"} value={0}/>
                                    </StyledTableCell>
                                </StyledTableRow>) :
                                !data || data.length === 0 ?
                                    (<StyledTableRow>
                                        <StyledTableCell colSpan={5} align={'center'}>Нет данных</StyledTableCell>
                                    </StyledTableRow>) :
                                    (rowsPerPage > 0
                                            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : data
                                    ).map((row, index) =>
                                        <StyledTableRow hover tabIndex={-1} key={index}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <StyledTableCell key={column.id} align={column.align}>
                                                        {value ?
                                                            column.format ?
                                                                column.format(value)
                                                                : value
                                                            : ''}
                                                    </StyledTableCell>
                                                );
                                            })}
                                        </StyledTableRow>
                                    )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[{ value: -1, label: 'Все' }]}
                    component="div"
                    count={!data ? 0 : data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage='Строк на странице'
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
        </Grid>
    </Grid>)
}