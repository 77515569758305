import {Navigate, Route, Routes, useNavigate} from "react-router-dom"
import AppLayout from "./AppLayout"
import Login from "./login/Login"
import React, {useEffect} from "react"
import UserInfo from "./info/UserInfo"
import SmsInfo from "./info/SmsInfo"
import TransactionsInfo from "./info/TransactionsInfo"
import ReportDashboard from "./report/ReportDashboard"
import ReportRepayment from "./report/ReportRepayment"
import CampaignsList from "./rotator/CampaignsList"
import AppDashboard from "./AppDashboard"
import {useAppContext} from "./AppContextProvider"
import {otpApi} from "../api/otp"
import logout from "../handlers/auth/logout";
import BoxInfo from "./info/BoxInfo";
import ChangePassword from "./ChangePassword";
import {jwtDecode} from "jwt-decode";


export default function AppRoutes() {

  const {state, dispatch} = useAppContext()
  const navigate = useNavigate()

  useEffect(() => {
    console.log('checking token')
    state.token && otpApi.checkToken().then(valid => {
      if (!valid) logout(dispatch, navigate)
    })
  }, [state.token])

  const isTokenExistsAndValid = (() => {
    if (!state.token) return false

    let token
    try {
      token = jwtDecode(state.token)
    } catch (_) {
      return false
    }

    return !(!token || !token.exp || (Date.now() / 1000) > token.exp);
  })()

  return (
    <Routes>
      {isTokenExistsAndValid &&
      <Route path="/" element={<AppLayout/>}>
        <Route index element={<AppDashboard/>} />
        <Route path="info" element={<UserInfo/>} />
        <Route path="sms" element={<SmsInfo/>} />
        <Route path="transactions" element={<TransactionsInfo/>} />
        <Route path="report" element={<ReportDashboard/>} />
        <Route path="report/repayment" element={<ReportRepayment/>} />
        <Route path="campaigns" element={<CampaignsList/>} />
        <Route path="box" element={<BoxInfo/>} />
        <Route path="change-password"
            element={
              state.token && state.authType === "login" ? (
                  <ChangePassword />
              ) : (
                  <Navigate to="/" replace />
              )
            }
        />
      </Route>
      }
      {!isTokenExistsAndValid &&
      <Route path="*" element={<Login/>} />
      }
    </Routes>
  )
}
