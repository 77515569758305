import {
    Card, CardHeader, CardContent,
    Button, CircularProgress, Divider,
    Grid, TextField, Typography, Stack,
} from '@mui/material'
import {useEffect, useState} from "react"
import {MnoInfo, reportApi, SubscriberInfo} from "../../api/report"
import {formatCurrency, formatDtHuman, parseDtExternal, isNumber} from "../../utils/format"
import { useSnackbar } from 'notistack';
import logout from "../../handlers/auth/logout";
import {useAppContext} from "../AppContextProvider";
import {useNavigate} from "react-router-dom";

export default function UserInfo() {
    const {dispatch} = useAppContext();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();
    
    const [msisdn, setMsisdn] = useState<string>('');
    const [msisdnError, setMsisdnError] = useState(true);
    const [msisdnErrorMessage, setMsisdnErrorMessage] = useState('');

    const [loading, setLoading] = useState(false);
    const [mkLoading, setMkLoading] = useState(false);
    const [mnoLoading, setMnoLoading] = useState(false);

    const [mkData, setMkData] = useState<SubscriberInfo>();
    const [mnoData, setMnoData] = useState<MnoInfo>();

    useEffect(() => {
    }, [])

    const handleLoadButtonClick = () => {
        loadData(msisdn);

    }

    function logoutIfUnauthorized(errorMsg: string | undefined) {
        if (errorMsg && errorMsg == 'Unauthorized') {
            logout(dispatch, navigate);

        }
    }

    function loadData(msisdn: string) {
        setLoading(true);
        setMkLoading(true);
        setMnoLoading(true);

        setMkData({} as SubscriberInfo);
        setMnoData({} as MnoInfo);

        reportApi.fetchBalance(msisdn).then(
            response => {
                if(response) {
                    if(response.error) {
                        logoutIfUnauthorized(response.error.message);

                        console.log('fetchBalance error', response.error);
                        enqueueSnackbar('Ошибка загрузки данных МК: ' + response.error.message, { variant: 'error' });

                    } else {
                        setMkData(response);

                    }
                }
            },
            error => {
                logoutIfUnauthorized(error.message);

                console.log('fetchBalance error', error);
                enqueueSnackbar('Ошибка загрузки данных МК: ' + error.message, { variant: 'error' });

            }
        )
            .then(() => {
                setMkLoading(false);
                setLoading(mnoLoading);

            })

        reportApi.fetchMnoInfo(msisdn).then(
            response => {
                if(response) {
                    setMnoData(response);

                }
            },
            error => {
                logoutIfUnauthorized(error.message);

                console.log('fetchMnoInfo error', error);

                let msg = error.message;
                if(error.message && error.message.startsWith('invalid character')) {
                    msg = 'Абонент не найден';
                }

                enqueueSnackbar('Ошибка загрузки данных MNO: ' + msg, { variant: 'error' });

            }
        )
            .then(() => {
                setMnoLoading(false);
                setLoading(mkLoading);

            })
    }

    //

    const handleMsisdnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newMsisdn = event.target.value;

        const badSymbols = /\D+/g
        newMsisdn = newMsisdn.replaceAll(badSymbols, "")

        if(!isNumber(newMsisdn)) {
            setMsisdnError(true);
            setMsisdnErrorMessage('Номер телефона должен содержать только цифры');

        } else {
            setMsisdnError(false);
            setMsisdnErrorMessage('');

        }

        setMsisdn(newMsisdn);

    };

    //

    function createCard(title: string, dataContainer: any, loading: boolean) {
        return (
            <Card>
                <CardHeader
                    title={title}
                    style={{ fontFamily: 'serif-bold' }}
                />
                <CardContent>
                    { loading ?
                        <Grid item style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'}}>
                            <CircularProgress variant={loading ? "indeterminate" : "determinate"} value={0}/>
                        </Grid> : dataContainer }
                </CardContent>
            </Card>
        );
    }

    function createCardRow<T>(title: string, rawValue: any, value?: (t: T) => string) {
        const formatter = value || function (t: T) {
            return `${t}`
        }

        return (
            <Grid container spacing={2} style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                <Grid item>
                    <Typography variant="body2" color="text.secondary" style={{ fontFamily: 'sans' }}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" color="text.secondary" style={{ fontFamily: 'serif' }}>
                        {rawValue ?
                            formatter(rawValue) : '-'}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    //

    return (<Grid container spacing={2}>
        <Grid item>
            <TextField
                style={{ width: 334 }}
                size='small'
                error={msisdnError}
                helperText={msisdnErrorMessage}
                id="msisdn"
                label="Номер телефона"
                variant="outlined"
                value={msisdn}
                disabled={loading}
                onChange={handleMsisdnChange}
            />
        </Grid>
        <Grid item>
            <Button
                color='primary'
                disabled={loading || msisdnError}
                onClick={handleLoadButtonClick}>
                Загрузить
            </Button>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        <Grid item xs={6}>
            { createCard(
                'Данные МК',
                <div>
                    {createCardRow('Полный баланс', mkData?.balanceTotal, formatCurrency)}
                    {createCardRow('Доступно для МК', mkData?.balance, formatCurrency)}
                    {createCardRow('С упрощенными проверками', mkData?.balanceSimpleChecks, formatCurrency)}
                    {createCardRow('Филиал', mkData?.branchId, (branchId) => {
                        return mkData?.branchName ? `${mkData.branchName} (${mkData.branchId})` : `${branchId}`;
                    })}
                    {createCardRow('ЮЛ', mkData?.branchOrg)}
                    {createCardRow('Идентификатор абонента', mkData?.subscriberId)}
                    {createCardRow('Дата активации', mkData?.activationDate, (d: string) => {
                        return formatDtHuman(parseDtExternal(d));
                    })}
                </div>,
                mkLoading
            ) }
        </Grid>
        <Grid item xs={6}>
            { createCard(
                'Оператор',
                <div>
                    {createCardRow('Оператор', mnoData?.title)}
                    {createCardRow('Регион', mnoData?.region, (r: string) => {
                        let text = r
                        if (mnoData?.capital) {
                            text = text + ` (${mnoData.capital})`
                        }
                        return text;
                    })}
                    {createCardRow('Часовой пояс', mnoData?.timezone)}
                    {createCardRow('ID', mnoData?.id)}
                </div>,
                mnoLoading
            ) }
        </Grid>
    </Grid>)
}
